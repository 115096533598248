

.spinner-padre {
  display: flex;
  position: absolute;
  top: 50%;
  left: 48%;
  justify-content: center;
  align-items: center;
  /* height: 300px;
  width: 1200px; */
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 10px solid black;
    border-color: yellow blue black red;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }