body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: radial-gradient(#345ba0 33%, transparent 33%);
  /* background: radial-gradient(#648acc 33%, transparent 33%); */
  background-size: 3px 3px;
  border-radius: 4px;
  position: absolute;
  z-index: -1;
}
.landing-full {
 
  background-color: #000000;
  background-image: url("../img/back2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;

  height: 100%;
  width: 100%;
}

.full-inner {
  width: 100%;
  vertical-align: middle;
  display: table-cell;
}

.landing-content {
  position: relative;
  max-width: 700px;
  margin: auto;
  text-align: left;
  right: 23%;
}

/* .landing-content h1 {
  border-radius: 9px;
  border: 3px solid;
  font-family: "Cooper Std Black", sans-serif;
  font-size: 500%;
  -webkit-text-stroke: 6px #345ba0;
  color: #ffcc03;
  text-shadow: 0 0 300px black;
  position: absolute;
} */

.landing-content h3 {
  border-radius: 9px;
  font-family: "Cooper Std Black", sans-serif;
  font-size: 200%;
  -webkit-text-stroke: 2px #345ba0;
  color: #ffcc03;
  text-shadow: 0 0 300px black;
  position: absolute;
  border: 3px solid;
  padding: 7px 15px;
  
}

/* .landing-content a {
  border-radius: 9px;
  text-decoration: none;
  color: #ffcc03;
  font-family: "Century Gothic", sans-serif;
  font-weight: bold;
  border: 3px solid;
  padding: 7px 15px;
  font-size: 200%;
} */

.landing-content h3:hover {
  color: #2196f3;
}

p {
  font-size: 160%;
  line-height: 200%;
  margin: 3%;
  font-family: sans-serif;
}
