.detail-content {

  gap: 30px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: 40px;
  background-color: #f5f4ee;
  border-radius: 20px;
  box-shadow: 10px 10px 4px #111;
  border: 3px solid #545455;
  
}


.detail-titulo
{
  height: 95%;
  border-radius: 10px;
  padding: 10px;
  /* background-color: #f5f4ee;
  box-shadow: 0 3px 15px rgba(100, 100, 100, 0.5); */
  margin-top: 40px;
  text-align: center;
  text-transform: capitalize;
}

.detail-block {
  display: grid; 
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 100%;
  
  
}

.detail-block-img{
  display: flex;
  justify-content: center;
  align-items: center;
}

.Link {
  text-decoration: none;
  color: black;
}


.detail-block-tipo{
  display: flex;    
  display: flex;
  align-items: center;
  gap: 30px;
  width: 90%;  
  border-radius: 4px;
  border: 1px dashed grey;
  margin-bottom: 10px;
  height: 20px;
  text-transform: capitalize;
}
.detail-block-tipo-titulo{
  display: flex;    
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  width: 90%;  

}




.detail-block-contenido{
  text-transform: capitalize;
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: 4px;
  border: 1px dashed grey;
  margin-bottom: 10px;
  width: 90%;
}

.detail-block-contenido p {

  font-size: 17px;
  font-weight: bold;
  text-transform: capitalize;
}

.detal-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #545455;
  margin-bottom: 10px;
  position: absolute;
  

 

}


.detail-btn {

background-color: rgb(118, 160, 233);
border: 1px solid #545455;
border-radius: 4px;
padding: 5px;
margin: 5px;
width: 200px;
height: 30px;
font-size: 14px;
font-weight: bold;
color: #fcfcfc;
transition: .5s;
text-transform: uppercase;
position: relative;
left: 200px; 


}


.detail-btn:hover {
background-color: #545455;
color: #fff;
cursor: pointer;
}


