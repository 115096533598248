.navbar-header {
    position: relative;
    height: 80px;
    top: 25px;
    width: auto;
    margin-top: -30px;
    padding: 10px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* -webkit-box-shadow: 0px 10px 10px 0px rgba(33, 33, 33, 0.35);
    -moz-box-shadow: 0px 10px 10px 0px rgba(33, 33, 33, 0.35);
    box-shadow: 0px 10px 10px 0px rgba(33, 33, 33, 0.35); */
  }
  
  .navbar-header .logo img {
    width: 200px;
   
  }

  .navbar-header .logo:hover {
    cursor: pointer;
  }
  

  .navbar-boton-content {
   display: flex;
   justify-content: space-between;
    

    }

  .navbar-boton-content button{
  
    /* background-color: #f5f4ee; */
    background-color: rgb(118, 160, 233);
    border: 1px solid #545455;
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-weight: bold;
    /* color: #545455; */
    color: #fcfcfc;
    transition: .5s;
  
    
  }
  
  .navbar-boton-content button:hover {
    background-color: #545455;
    color: #f5f4ee;
    cursor: pointer;
  }

  


  